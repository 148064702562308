import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import AuthGuard from "./AuthGuard";
// components
import LoadingScreen from "../components/LoadingScreen";
import PagePlayer from "../pages/PagePlayer";
import PageStations from "../pages/PageStations";

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const jwt = localStorage.getItem("jwt");
  return useRoutes([
    {
      path: "/",
      element: <Navigate to={jwt ? "/players" : "/auth/login"} replace />,
    },
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
      ],
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "home", element: <PageHome /> },
        { path: "transactions", element: <PageTransactions /> },
        { path: "players", element: <PagePlayers /> },
        { path: "player", element: <PagePlayer /> },
        { path: "stations", element: <PageStations /> },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// Dashboard
const Login = Loadable(lazy(() => import("../pages/PageLogin")));
const ForgotPassword = Loadable(
  lazy(() => import("../pages/PageForgotPassword"))
);
const PageHome = Loadable(lazy(() => import("../pages/PageHome")));
const PagePlayers = Loadable(lazy(() => import("../pages/PagePlayers")));
const PageTransactions = Loadable(
  lazy(() => import("../pages/PageTransactions"))
);
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
