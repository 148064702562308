import {
  Alert,
  Box,
  Button,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useGetTransactionListByPlayerIdQuery } from "../generated/graphql";
import { orderBy } from "lodash";
import SvgIconStyle from "./other/SvgIconStyle";

// ----------------------------------------------------------------------
interface PlayerTransactionsProps {
  player: any;
}

export default function PlayerTransactions({
  player,
}: PlayerTransactionsProps) {
  // Pagination
  const [cursor, setCursor] = useState<number | null>(null);
  const [limit, setLimit] = useState<number>(10);

  // Sorting
  const [sortDirectionAsc, setSortDirectionAsc] = useState<boolean>(false);
  const [sortByAttribute, setSortByAttribute] = useState<string>("date");

  // Concated List
  const [concatedList, setConcatedList] = useState<any[]>([]);

  const [
    { data: getPlayerTransactionsData, error: getPlayerTransactionsError },
  ] = useGetTransactionListByPlayerIdQuery({
    variables: {
      playerId: player.id,
      pagination: {
        limit: limit,
        cursor: cursor,
      },
    },
    requestPolicy: "network-only",
  });

  const transactions =
    getPlayerTransactionsData?.getTransactionListByPlayerId.transactions;
  const _cursor =
    getPlayerTransactionsData?.getTransactionListByPlayerId.cursor;
  const _hasMore =
    getPlayerTransactionsData?.getTransactionListByPlayerId.hasMore;
  const _totalCount =
    getPlayerTransactionsData?.getTransactionListByPlayerId.totalCount;

  // concat transactions list on new data
  // - If previous data is same as new data then don't concat
  useEffect(() => {
    if (transactions) {
      setConcatedList((prev) => {
        if (JSON.stringify(prev) === JSON.stringify(transactions)) {
          return prev;
        }
        return [...prev, ...transactions];
      });
    }
  }, [transactions]);

  const changeSort = (attribute: any) => {
    if (attribute === sortByAttribute) {
      const newSort = !sortDirectionAsc;
      setSortDirectionAsc(newSort);
    } else setSortByAttribute(attribute);
  };

  const sortItems = useMemo(() => {
    const direction = sortDirectionAsc ? "asc" : "desc";
    return orderBy(concatedList, (o) => o[sortByAttribute], direction);
  }, [concatedList, sortByAttribute, sortDirectionAsc]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          height: { xs: "auto", sm: "50px" },
          width: { xs: "100%", sm: "auto" },
          mb: 2,
        }}
      >
        {transactions && transactions.length === 0 ? (
          <Alert severity="warning">No Transactions Found!</Alert>
        ) : (
          <Alert severity="info">{_totalCount} Transactions Found</Alert>
        )}
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" sx={{ overflowX: "scroll" }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#2d2f38" }}>
              {/* Arrow Icon */}
              <TableCell align="left" />

              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("type")}
                  active={sortByAttribute === "type"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Transaction Type
                </TableSortLabel>
              </TableCell>
              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("amount")}
                  active={sortByAttribute === "amount"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Amount
                </TableSortLabel>
              </TableCell>

              {/* Bonus */}
              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("bonus")}
                  active={sortByAttribute === "bonus"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Bonus
                </TableSortLabel>
              </TableCell>

              <TableCell align="left">
                <TableSortLabel
                  onClick={() => changeSort("date")}
                  active={sortByAttribute === "date"}
                  direction={sortDirectionAsc ? "asc" : "desc"}
                >
                  Date
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortItems?.map((transaction: any, index: any) => {
              return (
                <PlayerTransactionRow
                  key={transaction.id}
                  transaction={transaction}
                  index={index}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {_hasMore && _cursor && (
        <Button
          onClick={() => {
            setCursor(_cursor);
          }}
          fullWidth
          variant={"contained"}
          sx={{
            mt: 2,
          }}
        >
          Load More
        </Button>
      )}
    </Box>
  );
}

const toHumanReadableDate = (isoString: string) => {
  const date = new Date(isoString);
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return `${formattedDate} at ${formattedTime}`;
};

const PlayerTransactionRow = (props: { transaction: any; index: number }) => {
  const [open, setOpen] = useState(false);
  const { transaction, index } = props;

  const isEven = index % 2 === 0;

  return (
    <>
      <TableRow
        key={transaction.id}
        sx={{
          backgroundColor: isEven ? "#2f3140" : "#3d4155",
          height: "45px",
        }}
      >
        <TableCell
          align="left"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!open);
          }}
        >
          {transaction.type == "DEPOSIT" && (
            <>
              {open ? (
                <SvgIconStyle
                  src={`/assets/arrow-up.svg`}
                  sx={{
                    width: 25,
                    height: 25,
                    my: "auto",
                  }}
                />
              ) : (
                <SvgIconStyle
                  src={`/assets/dropdown-arrow.svg`}
                  sx={{
                    width: 25,
                    height: 25,
                    my: "auto",
                  }}
                />
              )}
            </>
          )}
        </TableCell>

        <TableCell align="left">{transaction.type}</TableCell>
        <TableCell align="left">{transaction.amount}</TableCell>

        <TableCell align="left">{transaction.bonus}</TableCell>

        <TableCell align="left">
          {toHumanReadableDate(transaction.date)}
        </TableCell>
      </TableRow>

      {/* Collapsable Row */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="nested table">
                <TableHead>
                  <TableRow>
                    <TableCell> Package</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Free Entries</TableCell>
                    <TableCell> Aleon Tokens </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transaction.packages?.map((p: any) => (
                    <TableRow key={p.id}>
                      <TableCell component="th" scope="row">
                        {p.name}
                      </TableCell>
                      <TableCell>X {p.amount}</TableCell>
                      <TableCell>{p.entries}</TableCell>
                      <TableCell>{p.aleonTokens}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
