import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

// Populate using values in copy-paste JavaScript snippet.
const options = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] },
  }, // NREUM.init
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: 'NRJS-f946b46c9833ca9839f',
    applicationID: '1588985051',
    sa: 1,
  }, // NREUM.info
  loader_config: {
    accountID: '4626682',
    trustKey: '4626682',
    agentID: '1588985051',
    licenseKey: 'NRJS-f946b46c9833ca9839f',
    applicationID: '1588985051',
  }, // NREUM.loader_config
};

// The agent loader code executes immediately on instantiation.
new BrowserAgent(options);
