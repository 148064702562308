import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        // root: {
        //   '&:hover': {
        //     boxShadow: 'none',
        //     backgroundColor: '#c14c32',
        //   },
        // },
        sizeLarge: {
          height: 48,
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        containedError: {
          boxShadow: theme.customShadows.error,
        },
        // outlined
        // outlinedInherit: {
        //   border: `1px solid ${theme.palette.grey[500_32]}`,
        //   '&:hover': {
        //     backgroundColor: '#c14c32',
        //   },
        // },
        // textInherit: {
        //   '&:hover': {
        //     backgroundColor: '#c14c32',
        //   },
        // },
      },
    },
  };
}
