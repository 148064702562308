import { createContext, ReactNode, useEffect, useReducer } from "react";
import { Auth } from "../Auth";
import posthog from "posthog-js";
import { deleteActiveRoomIdAndName } from "../utils/deleteActiveRoomIdAndName";

// ----------------------------------------------------------------------

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type AuthUser = null | Record<string, any>;

type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  jwt: string;
  jwtDecoded: any;
  refreshToken: string;
  activeRoomName: undefined | string;
  activeRoomId: undefined | string;
};

type AuthContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  jwt: string;
  jwtDecoded: any;
  refreshToken: string;
  activeRoomName: undefined | string;
  activeRoomId: undefined | string;
  method: "Auth";
  login: (email: string, password: string) => Promise<any>;
  logout: VoidFunction;
  forgotPassword: (email: string) => Promise<any>;
  setJwt: (jwt: string) => Promise<void>;
  setDecodedJwt: (jwt: string) => Promise<void>;
  setRefreshToken: (refreshToken: string) => Promise<void>;
  setActiveRoom: (activeRoomName: string, activeRoomId: string) => void;
};

// ----------------------------------------------------------------------

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  jwt: "",
  jwtDecoded: null,
  refreshToken: "",
  activeRoomName: "",
  activeRoomId: undefined,
};

enum Types {
  init = "INITIALIZE",
  login = "LOGIN",
  logout = "LOGOUT",
  setJwt = "SET_JWT",
  setDecodedJwt = "SET_DECODED_JWT",
  setRefreshToken = "SET_REFRESHTOKEN",
  setActiveRoom = "SET_ACTIVE_ROOM",
}

type AuthAuthPayload = {
  [Types.init]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.login]: {
    user: AuthUser;
  };
  [Types.logout]: undefined;
  [Types.setJwt]: {
    jwt: string;
  };
  [Types.setDecodedJwt]: {
    jwtDecoded: any;
  };
  [Types.setRefreshToken]: {
    refreshToken: string;
  };
  [Types.setActiveRoom]: {
    activeRoomName: string;
    activeRoomId: string;
  };
};

type AuthActions = ActionMap<AuthAuthPayload>[keyof ActionMap<AuthAuthPayload>];

const reducer = (state: AuthState, action: AuthActions) => {
  if (action.type === Types.init) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === Types.login) {
    const { user } = action.payload;
    return { ...state, isAuthenticated: true, user };
  }
  if (action.type === Types.logout) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === Types.setJwt) {
    return {
      ...state,
      jwt: action.payload.jwt,
    };
  }
  if (action.type === Types.setDecodedJwt) {
    return {
      ...state,
      jwtDecoded: action.payload.jwtDecoded,
    };
  }
  if (action.type === Types.setRefreshToken) {
    return {
      ...state,
      refreshToken: action.payload.refreshToken,
    };
  }
  if (action.type === Types.setActiveRoom) {
    return {
      ...state,
      activeRoomName: action.payload.activeRoomName,
      activeRoomId: action.payload.activeRoomId,
    };
  }
  return state;
};

const AuthContext = createContext<AuthContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

const auth = new Auth();

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        var isAuthenticated = false;
        const jwtLocalStorage = localStorage.getItem("jwt");

        if (jwtLocalStorage !== null) {
          const jwtLocalStorageDecoded = decodeJWT(jwtLocalStorage);
          if (
            jwtLocalStorageDecoded &&
            typeof jwtLocalStorageDecoded !== "string" &&
            jwtLocalStorageDecoded.exp
          ) {
            const currentTime = Math.floor(Date.now() / 1000);
            if (jwtLocalStorageDecoded?.exp > currentTime)
              isAuthenticated = true;
          }
        }

        if (isAuthenticated) {
          var jwt = localStorage.getItem("jwt");
          setJwt(jwt!);
          setDecodedJwt(jwt!);
          dispatch({
            type: Types.init,
            payload: { isAuthenticated, user: null },
          });
        } else {
          dispatch({
            type: Types.init,
            payload: { isAuthenticated, user: null },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.init,
          payload: { isAuthenticated: false, user: null },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    // Delete previously Stored RoomId and RoomName to prevent causing errors with using an invalid roomId
    deleteActiveRoomIdAndName();
    try {
      const data = await auth.login(email, password);
      if (data?.token) {
        setJwt(data.token);
        setRefreshToken(data.refreshToken);
        setDecodedJwt(data.token);
        dispatch({ type: Types.login, payload: { user: null } });

        if (
          !import.meta.env.VITE_APP_API.includes("localhost") &&
          !import.meta.env.VITE_APP_API.includes("staging") &&
          posthog
        ) {
          posthog.identify(
            data.token, // Unique ID for the user
            {
              email: email,
            }
          );
        }
        return true;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const logout = async () => {
    try {
      await auth.logout();
    } catch (error) {
      console.error(error);
    } finally {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("jwt");
      window.location.href = "/auth/login";
      dispatch({ type: Types.logout });
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_APP_API}/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      if (!response.ok) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const setJwt = async (jwt: any) => {
    localStorage.setItem("jwt", jwt.toString());
    dispatch({ type: Types.setJwt, payload: { jwt: jwt } });
  };

  const setDecodedJwt = async (jwt: any) => {
    const jwtDecoded = decodeJWT(jwt);
    dispatch({
      type: Types.setDecodedJwt,
      payload: { jwtDecoded: jwtDecoded },
    });
  };

  function decodeJWT(token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  const setRefreshToken = async (refreshToken: any) => {
    localStorage.setItem("refreshToken", refreshToken.toString());
    dispatch({
      type: Types.setRefreshToken,
      payload: { refreshToken: refreshToken },
    });
  };

  const setActiveRoom = async (
    activeRoomName: string,
    activeRoomId: string
  ) => {
    const environment = import.meta.env.VITE_APP_API;
    if (environment.includes("staging")) {
      localStorage.setItem("staging-activeRoomName", activeRoomName);
      localStorage.setItem("staging-activeRoomId", activeRoomId);
    } else if (environment.includes("localhost")) {
      localStorage.setItem("localhost-activeRoomName", activeRoomName);
      localStorage.setItem("localhost-activeRoomId", activeRoomId);
    } else if (environment.includes("sandbox")) {
      localStorage.setItem("sandbox-activeRoomName", activeRoomName);
      localStorage.setItem("sandbox-activeRoomId", activeRoomId);
    } else {
      localStorage.setItem("activeRoomName", activeRoomName);
      localStorage.setItem("activeRoomId", activeRoomId);
    }
    dispatch({
      type: Types.setActiveRoom,
      payload: { activeRoomName: activeRoomName, activeRoomId: activeRoomId },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "Auth",
        activeRoomName: state?.activeRoomName,
        activeRoomId: state?.activeRoomId,
        jwt: state?.jwt,
        jwtDecoded: state?.jwtDecoded,
        refreshToken: state?.refreshToken,
        login,
        logout,
        forgotPassword,
        setJwt,
        setDecodedJwt,
        setRefreshToken,
        setActiveRoom,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
