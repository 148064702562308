// @mui
import { styled, Box, Link, Typography, Avatar } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1.5, 2, 1.5, 2),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
  operator: any;
};

export default function NavbarAccount({ isCollapse, operator }: Props) {
  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: "transparent",
          }),
        }}
      >
        <Avatar src={""} alt="AdminIcon" />
        <Box sx={{ mr: 1.5, px: 1.5 }}>
          <Typography variant="subtitle2" sx={{ color: "white" }} noWrap>
            {operator?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {operator?.adminRoleName}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
