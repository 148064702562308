// components
import { path } from "@tauri-apps/api";
import SvgIconStyle from "../../../components/other/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      // {
      //   title: "Home",
      //   path: "/home",
      //   icon: getIcon("home"),
      // },
      {
        title: "Players",
        path: "/players",
        icon: getIcon("user"),
        // disabled: true,
      },
      {
        title: "Transactions",
        path: "/transactions",
        icon: getIcon("dashboard"),
        // disabled: true,
      },
      {
        title: "Stations",
        path: "/stations",
        icon: getIcon("station"),
        // disabled: true,
      },
    ],
  },
];

export default navConfig;
