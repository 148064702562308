import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  CircularProgress,
  DialogContentText,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Iconify from "../components/Iconify";
import {
  useGetActivePackagesQuery,
  Package,
  usePrizeRedemptionMutation,
  useTopUpBalanceMutation,
  useUpdatePlayerPasswordMutation,
  useUpdatePlayerCardSerialNumberMutation,
  useUpdatePlayerMutation,
  useSetPlayerEmailAndSaltMutation,
} from "../generated/graphql";
// import phone from "phone";

import { invoke } from "@tauri-apps/api";
// import { FileUpload } from "primereact/fileupload";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------
interface PlayerAccountProps {
  player: any;
  getUpdatedPlayer: any;
}

export default function PlayerAccount({
  player,
  getUpdatedPlayer,
}: PlayerAccountProps) {
  const { activeRoomId } = useAuth();
  // Purchase Balance
  const [showTopUpDialog, setShowTopUpDialog] = useState(false);
  const [topUpBonus, setTopUpBonus] = useState(0);
  const [showAddFundsConfirmation, setShowAddFundsConfirmation] =
    useState(false);

  const [addBonus, setAddBonus] = useState(false);

  const [{}, topUpBalance] = useTopUpBalanceMutation();

  const handleTopUpBalance = async () => {
    await topUpBalance({
      playerId: player.id,
      packages: selectedPackages,
      bonus: topUpBonus,
    })
      .then(async (res) => {
        if (res.data?.topUpBalance) {
          toast.success("Successfully Added Funds ");
          setShowTopUpDialog(false);
          await getUpdatedPlayer({
            requestPolicy: "network-only",
          });
        }
      })
      .catch((err) => {
        toast.error(`ERR: ${err}`);
      })
      .finally(() => {
        setTopUpBonus(0);
      });
  };

  // update password
  const [showUpdatePasswordDialog, setShowUpdatePasswordDialog] =
    useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [{ error: updatePasswordError }, updatePassword] =
    useUpdatePlayerPasswordMutation();

  const handleUpdatePassword = () => {
    updatePassword({
      id: player.id,
    })
      .then((res) => {
        if (res.data?.updatePlayerPassword) {
          toast.success("Reset Password Email Sent Successfully");
          setShowUpdatePasswordDialog(false);
        } else {
          toast.error("Reset Password Email Sent Failed");
        }
      })
      .catch((err) => {
        toast.error(`ERR: ${err}`);
      });
  };

  // prizeRedemption
  const [showPrizeRedemptionDialog, setShowPrizeRedemptionDialog] =
    useState(false);
  const [prizeRedemptionAmount, setPrizeRedemptionAmount] = useState(0);

  const [{ error: prizeRedemptionError }, prizeRedemption] =
    usePrizeRedemptionMutation();

  const handleCashout = () => {
    if (prizeRedemptionAmount <= 0) {
      toast.error("Invalid Redeem amount");
      return;
    }

    if (prizeRedemptionAmount > player.prizeRedemption) {
      toast.error("Prize Redemption amount is more than the available balance");
      return;
    }

    prizeRedemption({
      id: player.id,
      amount: prizeRedemptionAmount,
    })
      .then((res: any) => {
        if (res.data?.prizeRedemption) {
          getUpdatedPlayer();
          toast.success("Prize redeemed successfully");
          setPrizeRedemptionAmount(0);
        }
      })
      .catch((err: any) => {
        toast.error(`ERR: ${err}`);
      })
      .finally(() => {
        setShowPrizeRedemptionDialog(false);
        setPrizeRedemptionAmount(0);
      });
  };

  // Issue card
  const [
    { error: updatePlayerCardSerialNumberError },
    updatePlayerCardSerialNumber,
  ] = useUpdatePlayerCardSerialNumberMutation();

  const [showCardScanDialog, setShowCardScanDialog] = useState(false);
  const handleIssueCard = async (userId: string) => {
    setShowCardScanDialog(true);

    try {
      // hit the Tauri Rust function to write userId to the card
      const cardSerialNumber = await invoke(
        "plugin:tauri_plugin_nfc|issue_card",
        {
          userId,
        }
      );
      // hit GQL endpoint to save the player card serial number to DB
      await updatePlayerCardSerialNumber({
        playerId: player.id,
        cardSerialNumber: cardSerialNumber as string,
      });
      getUpdatedPlayer();
      toast.success("Issued Player Card!");
    } catch (e) {
      toast.error("Error issuing card:", e);
    }
    setShowCardScanDialog(false);
  };

  // deactivate card
  const handleDeactivateCard = async () => {
    try {
      await updatePlayerCardSerialNumber({
        playerId: player.id,
        cardSerialNumber: null,
      });
      getUpdatedPlayer();
      toast.success("Deactivated Player Card!");
    } catch (e) {
      toast.error("Error issuing card:", e);
    }
    setShowCardScanDialog(false);
  };

  // Setup Home Play Account
  const [{ error: updatePlayerEmailAndPasswordError }, setPlayerEmailAndSalt] =
    useSetPlayerEmailAndSaltMutation();
  const [
    showCreatePlayerEmailAndPasswordDialog,
    setShowCreatePlayerEmailAndPasswordDialog,
  ] = useState(false);

  const handleCreatePlayerEmail = async () => {
    if (!isValidEmail(email)) {
      toast.error("Invalid email");
      return;
    }
    setPlayerEmailAndSalt({
      id: player.id,
      email: email,
    })
      .then((res) => {
        if (res.data) {
          toast.success(
            "Account setup successfully, set password link sent to email"
          );
          getUpdatedPlayer();
          setShowCreatePlayerEmailAndPasswordDialog(false);
        } else {
          toast.error("HomePlay account setup failed");
        }
      })
      .catch((err) => {
        toast.error(`ERR: ${err}`);
      })
      .finally(() => {});
  };

  // Update Player Info
  const [username, setUserName] = useState(player?.username);
  const [firstName, setFirstName] = useState(player?.firstName);
  const [lastName, setLastName] = useState(player?.lastName || "");
  const [email, setEmail] = useState(player.email);
  const [phoneNumber, setPhoneNumber] = useState(player.phoneNumber);
  const [images, setImages]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [updatePlayerDialogOpen, setUpdatePlayerDialogOpen] = useState(false);
  const [cautionRoomIdMismatch, setCautionRoomIdMismatch] = useState(false);

  const [{ error: updatePlayerError }, updatePlayer] =
    useUpdatePlayerMutation();

  const handleUpdatePlayerDetails = () => {
    if (email && !isValidEmail(email)) {
      toast.error("Invalid email");
      return;
    }
    setLoading(true);
    updatePlayer({
      input: {
        username: username,
        playerId: player.id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        images: images,
      },
    }).then((res) => {
      if (res.error) {
        toast.error(`ERR: ${res.error}`);
      } else {
        toast.success("Successfully updated");
        setUpdatePlayerDialogOpen(false);
        getUpdatedPlayer();
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (activeRoomId !== player.roomId) {
      setCautionRoomIdMismatch(true);
    }
  }, []);

  const [{ data: getActivePackagesData }, getActivePackages] =
    useGetActivePackagesQuery({});
  const _packages = getActivePackagesData?.getActivePackages;

  const [selectedPackages, setSelectedPackages] = useState<number[]>([]);

  const handleAddFunds = (packageId: number) => {
    setSelectedPackages([...selectedPackages, packageId]);
  };

  const handleRemoveFunds = (packageId: number) => {
    setSelectedPackages(selectedPackages.filter((id) => id !== packageId));
  };

  const totalCost = selectedPackages.reduce((acc, curr) => {
    return acc + (_packages?.find((p) => p.id === curr)?.price || 0);
  }, 0);

  const totalAleonTokens = selectedPackages.reduce((acc, curr) => {
    return acc + (_packages?.find((p) => p.id === curr)?.aleonTokens || 0);
  }, 0);

  const totalEntries = totalCost + topUpBonus;

  return (
    <Box>
      {/* Player Balance */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexFlow: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "200px",
            backgroundColor: "#2f3140",
            p: 2,
            mx: 2,
            my: 1,
            borderRadius: "5%",
          }}
        >
          <Typography
            variant="h4"
            component={"span"}
            sx={{ justifySelf: "center" }}
          >
            Bonus
          </Typography>

          <Typography
            variant="h5"
            component={"span"}
            sx={{ justifySelf: "center" }}
          >
            {player.bonus !== undefined ? player.bonus : "N/A"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "200px",
            backgroundColor: "#2f3140",
            p: 2,
            mx: 2,
            my: 1,
            borderRadius: "5%",
          }}
        >
          <Typography
            variant="h4"
            component={"span"}
            sx={{ justifySelf: "center" }}
          >
            Free Entry
          </Typography>

          <Typography
            variant="h5"
            component={"span"}
            sx={{ justifySelf: "center" }}
          >
            {player.entry !== undefined ? player.entry : "N/A"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "200px",
            backgroundColor: "#2f3140",
            p: 2,
            mx: 2,
            my: 1,
            borderRadius: "5%",
          }}
        >
          <Typography
            variant="h4"
            component={"span"}
            sx={{ justifySelf: "center" }}
          >
            Wininngs
          </Typography>
          <Typography
            variant="h5"
            component={"span"}
            sx={{ justifySelf: "center" }}
          >
            {player.winnings !== undefined ? player.winnings : "N/A"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "200px",
            height: "90px",
            backgroundColor: "#2f3140",
            p: 2,
            mx: 2,
            my: 1,

            borderRadius: "5%",
          }}
        >
          <Typography
            variant="body1"
            component={"span"}
            sx={{ textAlign: "center" }}
          >
            Prize Redemption
          </Typography>
          <Typography
            variant="h5"
            component={"span"}
            sx={{ justifySelf: "center" }}
          >
            {player.prizeRedemption !== undefined
              ? parseInt(player.prizeRedemption)
              : "N/A"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "200px",
            height: "90px",
            backgroundColor: "#2f3140",
            p: 2,
            mx: 2,
            my: 1,

            borderRadius: "5%",
          }}
        >
          <Typography
            variant="body1"
            component={"span"}
            sx={{ textAlign: "center" }}
          >
            Aleon Tokens
          </Typography>
          <Typography
            variant="h5"
            component={"span"}
            sx={{ justifySelf: "center" }}
          >
            {player.aleonTokens ? player.aleonTokens : "N/A"}
          </Typography>
        </Box>
      </Box>
      {/* Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 2,
          mt: 4,
          flexFlow: "wrap",
        }}
      >
        <Button
          variant="outlined"
          sx={{ width: "150px", mr: 2, my: 1 }}
          onClick={() => {
            setShowTopUpDialog(true);
          }}
        >
          Purchase
        </Button>

        <Button
          disabled={player.prizeRedemption === 0}
          variant="outlined"
          sx={{ width: "150px", mr: 2, my: 1 }}
          onClick={() => {
            setShowPrizeRedemptionDialog(true);
          }}
        >
          Redeem
        </Button>

        <Button
          disabled={player.cardSerialNumber}
          variant="outlined"
          sx={{ width: "150px", mr: 2, my: 1 }}
          onClick={() => {
            handleIssueCard(player.id);
          }}
        >
          Issue Card
        </Button>

        <Button
          disabled={!player.cardSerialNumber}
          variant="outlined"
          sx={{ width: "150px", mr: 2, my: 1 }}
          onClick={() => {
            handleDeactivateCard();
          }}
        >
          Deactivate Card
        </Button>

        <Button
          variant="outlined"
          sx={{ width: "150px", mr: 2, my: 1 }}
          onClick={() => {
            setUpdatePlayerDialogOpen(true);
          }}
        >
          Update Player Info
        </Button>

        <Button
          disabled={!player.isPlayFromHome}
          variant="outlined"
          sx={{ width: "150px", mr: 2, my: 1 }}
          onClick={() => {
            setShowUpdatePasswordDialog(true);
          }}
        >
          Update Password
        </Button>

        <Button
          disabled={player.isPlayFromHome}
          variant="outlined"
          sx={{ width: "150px", mr: 2, my: 1 }}
          onClick={() => {
            setShowCreatePlayerEmailAndPasswordDialog(true);
          }}
        >
          Set-up HomePlay
        </Button>
      </Box>

      {/* Player Details */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          my: 2,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#2a3039",
            py: 4,
          }}
        >
          <Typography variant="h4" sx={{ ml: 1, flex: 1 }}>
            Player Details
          </Typography>
        </Box>
        {/* Player Id */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#3d4155",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Player ID *
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.id}
          </Typography>
        </Box>
        {/* Player User Name */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#2f3140",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Username
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.username ? player.username : "N/A"}
          </Typography>
        </Box>
        {/* Player First Name */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#3d4155",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            First Name *
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.firstName ? player.firstName : "N/A"}
          </Typography>
        </Box>
        {/* Player Last Name */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#2f3140",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Last Name
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.lastName ? player.lastName : "N/A"}
          </Typography>
        </Box>
        {/* Player Email */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#3d4155",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Email
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.email ? player.email : "N/A"}
          </Typography>
        </Box>
        {/* Phone Number */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#2f3140",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Phone Number
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.phoneNumber ? formatPhoneNumber(player.phoneNumber) : "N/A"}
          </Typography>
        </Box>
        {/* Player Password */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#3d4155",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Password
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.isPlayFromHome ? "********" : "N/A"}
          </Typography>
        </Box>
        {/* Player Card Serial Number */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#2f3140",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Card Serial Number
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.cardSerialNumber ? player.cardSerialNumber : "N/A"}
          </Typography>
        </Box>
        {/* RoomId */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "45px",
            bgcolor: "#3d4155",
            textAlign: "start",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            Room ID *
          </Typography>
          <Typography variant="body2" sx={{ ml: 1, flex: 1 }}>
            {player.roomId ? player.roomId : "N/A"}
          </Typography>
        </Box>
      </Box>

      {/* Purchase Dialog */}
      <Dialog
        fullScreen
        onClose={() => {
          setShowTopUpDialog(false);
          setShowAddFundsConfirmation(false);
        }}
        open={showTopUpDialog}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#2e3c5a",
          }}
        >
          <Typography sx={{ mx: "auto", mt: 12 }} variant="h2">
            Purchase Package - {player.firstName}
          </Typography>

          <IconButton
            size="large"
            aria-label="close"
            onClick={() => {
              setShowTopUpDialog(false);
              setShowAddFundsConfirmation(false);
            }}
            sx={{
              backgroundColor: "#3d4155",
              width: "100px",
              height: "100px",
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
              fontSize: "2rem",
              ":hover": { color: "red" },
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "500px",
            bgcolor: "#2e3c5a",
            height: "100%",
          }}
        >
          {showAddFundsConfirmation ? (
            <Box
              sx={{
                mx: "auto",
                display: "flex",
                flexDirection: "column",
                mb: 16,
              }}
            >
              <Typography variant="h3" sx={{ mb: 4, fontWeight: "bold" }}>
                Are you sure you want to purchase the following packages?
              </Typography>

              <Box
                sx={{
                  m: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  justifyItems: "center",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={{ fontWeight: "bold" }} variant="h4">
                    Total Aleon Tokens
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }} variant="h4">
                    {totalAleonTokens}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }} variant="h4">
                    {`Total Free Entries`}
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }} variant="h4">
                    {totalEntries}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    ml: 2,
                  }}
                >
                  <Typography variant="h5">{`- Free Entries`}</Typography>
                  <Typography variant="h5">{totalCost}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    ml: 2,
                  }}
                >
                  <Typography variant="h5">{`- Free Entries (Bonus)`}</Typography>
                  <Typography variant="h5">{topUpBonus}</Typography>
                </Box>

                {/* Divider */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "3px solid white",
                    my: 1,
                  }}
                />
                {/* Total Price */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold" }} variant="h4">
                    Total Price
                  </Typography>
                  <Typography sx={{ fontWeight: "bold" }} variant="h4">
                    $ {(totalCost / 100).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
              {/* Confirm Actions (2) */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <>
                  <Button
                    fullWidth
                    sx={{ px: 8, py: 2, fontSize: "1.5rem" }}
                    variant="outlined"
                    autoFocus
                    onClick={(e) => setShowAddFundsConfirmation(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    fullWidth
                    sx={{ ml: 2, px: 4, py: 2, fontSize: "1.5rem" }}
                    variant="contained"
                    autoFocus
                    onClick={(e) => {
                      if (!showAddFundsConfirmation) {
                        setShowAddFundsConfirmation(true);
                        return;
                      }
                      handleTopUpBalance();
                    }}
                  >
                    Confirm Purchase
                  </Button>
                </>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                mb: 10,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {_packages?.map((_package) => {
                  const amountSelected = selectedPackages.filter(
                    (id) => id === _package.id
                  ).length;
                  return (
                    <Box
                      key={_package.id}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        height: "175px",
                        width: "200px",
                        bgcolor: "#3d4155",
                        borderRadius: "10px",
                        p: 2,
                        m: 2,
                        textAlign: "start",
                        position: "relative",
                      }}
                    >
                      <Typography variant="h5">{_package.name}</Typography>
                      <Typography variant="body1">
                        Price: {(_package.price / 100).toFixed(2)}
                      </Typography>
                      <Typography variant="body1">
                        FREE entry: {_package.entries}
                      </Typography>

                      <Typography variant="body1">
                        Aleon Tokens: {_package.aleonTokens}
                      </Typography>
                      <Button
                        onClick={() => handleAddFunds(_package.id)}
                        variant="contained"
                        sx={{
                          position: "absolute",
                          top: -5,
                          right: -5,
                          fontSize: "2rem",
                          width: "20px",
                          height: "40px",
                        }}
                      >
                        +
                      </Button>
                      <Button
                        onClick={() => handleRemoveFunds(_package.id)}
                        variant="contained"
                        sx={{
                          position: "absolute",
                          top: -5,
                          left: -5,
                          fontSize: "2rem",
                          width: "20px",
                          height: "40px",
                        }}
                      >
                        -
                      </Button>

                      <Typography
                        variant="body2"
                        sx={{
                          position: "absolute",
                          bottom: -5,
                          width: "35px",
                          height: "35px",
                          backgroundColor:
                            amountSelected == 0 ? "red" : "green",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "50%",
                        }}
                      >
                        {amountSelected}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 8,
                }}
              >
                <Box sx={{ width: "375px" }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      my: 2,
                    }}
                    variant="h6"
                  >
                    Do you want to add bonus to the purchase?
                  </Typography>

                  {
                    <TextField
                      fullWidth
                      label="Bonus"
                      sx={{
                        mb: 4,
                      }}
                      value={topUpBonus == 0 ? "" : topUpBonus}
                      onChange={(e) => {
                        if (e.target.value === "") {
                          setTopUpBonus(0);
                          return;
                        } else {
                          setTopUpBonus(parseFloat(e.target.value));
                        }
                      }}
                      type="number"
                    />
                  }

                  <Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography variant="h4">Total Aleon Tokens</Typography>
                      <Typography variant="h4">{totalAleonTokens}</Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 2,
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }} variant="h4">
                        {`Total Free Entries`}
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }} variant="h4">
                        {totalEntries}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        ml: 2,
                      }}
                    >
                      <Typography variant="h5">{`- Free Entries`}</Typography>
                      <Typography variant="h5">{totalCost}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        ml: 2,
                      }}
                    >
                      <Typography variant="h5">{`- Free Entries (Bonus)`}</Typography>
                      <Typography variant="h5">{topUpBonus}</Typography>
                    </Box>

                    {/* Divider */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "4px solid white",
                        my: 1,
                      }}
                    />

                    {/* Total Price */}
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography sx={{}} variant="h4">
                        Total Price
                      </Typography>
                      <Typography sx={{ fontWeight: "bold" }} variant="h4">
                        $ {(totalCost / 100).toFixed(2)}
                      </Typography>
                    </Box>

                    {/* Confirm Purchase (1) */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Button
                        sx={{ p: 2, fontSize: "1.5rem" }}
                        fullWidth
                        variant="contained"
                        autoFocus
                        onClick={(e) => {
                          if (!showAddFundsConfirmation) {
                            setShowAddFundsConfirmation(true);
                            return;
                          }
                          handleTopUpBalance();
                        }}
                      >
                        Confirm Purchase
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Dialog>
      {/* --------------------------------*/}
      {/* Prize Redemption Dialog */}
      <Dialog
        onClose={() => setShowPrizeRedemptionDialog(false)}
        open={showPrizeRedemptionDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#2e3c5a" }}>
          <Typography variant="h4">Prize Redemption</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setShowPrizeRedemptionDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "500px",
            bgcolor: "#2e3c5a",
          }}
        >
          <Typography variant="body2" sx={{ mb: 2 }}>
            Prize Redemption Amount must be less than or equal to the Prize
            Redemption Balance
          </Typography>

          <Typography variant="h6" sx={{ mb: 2 }}>
            Prize Redemption Balance: {parseInt(player.prizeRedemption)}
          </Typography>

          <TextField
            label="Prize Redemption"
            value={prizeRedemptionAmount == 0 ? "" : prizeRedemptionAmount}
            onChange={(e) => setPrizeRedemptionAmount(parseInt(e.target.value))}
            fullWidth
            type="number"
            sx={{
              mt: 2,
            }}
          />
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#2e3c5a" }}>
          <Button
            variant="contained"
            autoFocus
            onClick={(e) => handleCashout()}
          >
            Redeem Prize
          </Button>
        </DialogActions>
      </Dialog>
      {/* --------------------------------*/}
      {/* Card scan Dialog */}
      <Dialog
        open={showCardScanDialog}
        onClose={() => setShowCardScanDialog(false)}
      >
        <DialogTitle sx={{ bgcolor: "#2e3c5a" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Card Scan</Typography>
            <IconButton
              aria-label="close"
              onClick={() => setShowCardScanDialog(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Iconify icon="ep:close-bold" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ bgcolor: "#2e3c5a" }}>
          <DialogContentText id="card-scan-dialog-description">
            Tap the card to the card reader to issue player card for this player
          </DialogContentText>
          <Typography variant="body1" sx={{ mt: 2, fontWeight: "bold" }}>
            Tap the card to the card reader now
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
      {/* --------------------------------*/}
      {/* Password Update Dialog */}
      <Dialog
        onClose={() => setShowUpdatePasswordDialog(false)}
        open={showUpdatePasswordDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#2e3c5a" }}>
          Update Password
          <IconButton
            aria-label="close"
            onClick={() => setShowUpdatePasswordDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: "400px",
            minWidth: "350px",
            bgcolor: "#2e3c5a",
          }}
        >
          <FormControl variant="outlined" fullWidth sx={{ mt: 4 }}>
            <InputLabel htmlFor="outlined-adornment-email">Email *</InputLabel>
            <OutlinedInput
              disabled
              id="outlined-adornment-email"
              type={"text"}
              value={player.email}
              label="Email *"
            />
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#2e3c5a" }}>
          <Button
            variant="contained"
            autoFocus
            onClick={(e) => handleUpdatePassword()}
          >
            Send Update Password Email
          </Button>
        </DialogActions>
      </Dialog>
      {/* --------------------------------*/}
      {/* Setup Home Play Account Dialog */}
      <Dialog
        onClose={() => setShowCreatePlayerEmailAndPasswordDialog(false)}
        open={showCreatePlayerEmailAndPasswordDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#2e3c5a" }}>
          <Typography variant="h4"> Setup Home Play Account</Typography>

          <IconButton
            aria-label="close"
            onClick={() => setShowCreatePlayerEmailAndPasswordDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "500px",
            maxWidth: "500px",
            bgcolor: "#2e3c5a",
          }}
        >
          <Typography variant="body2" sx={{ mb: 2 }}>
            Please enter the player's email and we'll send them an email to set
            up their password for Home Play
          </Typography>
          <TextField
            label="Email *"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            sx={{
              mt: 4,
            }}
          />
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#2e3c5a" }}>
          <Button
            variant="contained"
            autoFocus
            onClick={(e) => handleCreatePlayerEmail()}
          >
            Create Account
          </Button>
        </DialogActions>
      </Dialog>
      {/* --------------------------------*/}
      {/* Player Update Dialog */}
      <Dialog
        onClose={() => {
          setUpdatePlayerDialogOpen(false);
        }}
        open={updatePlayerDialogOpen}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#2e3c5a" }}>
          Edit Player Details
          <IconButton
            aria-label="close"
            onClick={() => {
              setUpdatePlayerDialogOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "600px",
            maxWidth: "600px",
            bgcolor: "#2e3c5a",
          }}
        >
          <TextField
            label="Username"
            variant="outlined"
            sx={{ mt: 2 }}
            fullWidth
            defaultValue={player.username}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />

          <TextField
            label="First Name"
            variant="outlined"
            sx={{ my: 2 }}
            fullWidth
            defaultValue={player.firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            sx={{ mb: 2 }}
            fullWidth
            defaultValue={player.lastName}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />

          {player.isPlayFromHome && (
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              defaultValue={player.email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          )}
          <TextField
            label="Phone Number"
            variant="outlined"
            sx={{ mt: 2 }}
            fullWidth
            defaultValue={formatPhoneNumber(player.phoneNumber)}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />

          {/* Upload --- FEATURE OFF */}
          {/* <Box sx={{ mt: 2 }}>
            <FileUpload
              style={{
                maxHeight: "150px",
                overflow: "auto ",
              }}
              name="FileUploader[]"
              multiple
              accept="image/*"
              maxFileSize={1000000}
              customUpload
              itemTemplate={(file: any) => {
                return (
                  <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                    <img
                      alt={file.name}
                      role="presentation"
                      src={file.objectURL}
                      width={50}
                    />
                    <span style={{ marginLeft: "10px" }}>
                      {file.name} {"-"}
                    </span>
                    <span style={{ marginLeft: "10px" }}>
                      {file.size} bytes
                    </span>
                  </Box>
                );
              }}
              uploadHandler={(e) => {
                setImages(e.files);
              }}
              onClear={() => {
                setImages([]);
              }}
              emptyTemplate={
                <p style={{ marginTop: "10px" }}>
                  Drag and drop files to here to upload.
                </p>
              }
            />
          </Box> */}
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#2e3c5a" }}>
          <Button
            disabled={loading}
            variant="contained"
            autoFocus
            onClick={() => {
              handleUpdatePlayerDetails();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* --------------------------------*/}
      {/* Player roomId doesn't match active room Id Dialog */}
      <Dialog
        onClose={() => {
          setCautionRoomIdMismatch(false);
        }}
        open={cautionRoomIdMismatch}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2, bgcolor: "#2e3c5a" }}>
          Caution - Player Room ID Mismatch
          <IconButton
            aria-label="close"
            onClick={() => {
              setCautionRoomIdMismatch(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ep:close-bold" />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            minWidth: "600px",
            maxWidth: "600px",
            bgcolor: "#2e3c5a",
          }}
        >
          <Typography variant="body1" sx={{ mt: 2 }}>
            The player's room ID doesn't match the active room ID. Please make
            sure the player is in the correct room.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ bgcolor: "#2e3c5a" }}>
          <Button
            disabled={loading}
            variant="contained"
            autoFocus
            onClick={() => {
              setCautionRoomIdMismatch(false);
            }}
          >
            I Understand
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function isValidEmail(email: string) {
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
}

function formatPhoneNumber(phoneNumber: any) {
  if (phoneNumber === null) return "N/A";
  // Step 2: Sanitize the input to remove non-numeric characters
  const sanitizedNumber = phoneNumber?.replace(/\D/g, "");

  // Step 3: Check the length of the sanitized number to decide on the formatting pattern
  // Assuming a US phone number format for this example
  if (sanitizedNumber.length === 10) {
    // Step 4: Format the number
    return `(${sanitizedNumber.substring(0, 3)}) ${sanitizedNumber.substring(
      3,
      6
    )}-${sanitizedNumber.substring(6)}`;
  } else if (sanitizedNumber.length > 10) {
    // Assuming the first part is the country code
    const countryCode = sanitizedNumber.substring(
      0,
      sanitizedNumber.length - 10
    );
    const mainNumber = sanitizedNumber.substring(sanitizedNumber.length - 10);
    return `+${countryCode} (${mainNumber.substring(
      0,
      3
    )}) ${mainNumber.substring(3, 6)}-${mainNumber.substring(6)}`;
  } else {
    // If the number doesn't match expected lengths, return it as is or handle accordingly
    return phoneNumber;
  }
}
