import { useEffect } from 'react';
import toast from 'react-hot-toast';

// @mui
import { AppBar, Toolbar, styled } from '@mui/material';

// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
import useAuth from '../../../hooks/useAuth';

// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/other/animate';
// import { useGetSweepExpirationQuery } from 'src/generated/graphql';

// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive('up', 'lg');

  const { logout } = useAuth();

  //const [{ data: sweepExpiryResults, error: sweepExpiryError }] = useGetSweepExpirationQuery();
  const sweepExpiry = null; //sweepExpiryResults?.getSweepExpiration;
  // console.info('sweepExpiry', sweepExpiry);

  // useEffect(() => {
  //   if (sweepExpiryError?.response?.status === 401) logout();
  //   // else if (sweepExpiryError) toast.error(`ERR: ${sweepExpiryError}`);
  // }, [sweepExpiryError]);

  if (isDesktop) {
    // DESKTOP VIEW
    return (
      <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
        <Toolbar
          sx={{
            minHeight: '100% !important',
            px: { lg: 5 },
            display: 'flex',
          }}
        >
          {verticalLayout && <Logo />}
        </Toolbar>
      </RootStyle>
    );
  }

  // MOBILE VIEW
  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
          display: 'flex',
        }}
      >
        <IconButtonAnimate
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: 'text.primary' }}
          id="menu-show-button"
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButtonAnimate>
      </Toolbar>
    </RootStyle>
  );
}
