export const deleteActiveRoomIdAndName = () => {
  const environment = import.meta.env.VITE_APP_API;
  // Set active room from local storage - Based on environment
  if (environment.includes("staging")) {
    localStorage.removeItem("staging-activeRoomId");
    localStorage.removeItem("staging-activeRoomName");
  } else if (environment.includes("sandbox")) {
    localStorage.removeItem("sandbox-activeRoomId");
    localStorage.removeItem("sandbox-activeRoomName");
  } else if (environment.includes("localhost")) {
    localStorage.removeItem("localhost-activeRoomId");
    localStorage.removeItem("localhost-activeRoomName");
  } else {
    localStorage.removeItem("activeRoomId");
    localStorage.removeItem("activeRoomName");
  }
};
