import { useState, useEffect } from "react";
import { Box, Fab } from "@mui/material";
import { useCreateDiscordChannelMutation } from "../../generated/graphql";
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

export default function DiscordWidgetBot() {
  const [channel, setChannel] = useState("");
  const [isFabVisible, setIsFabVisible] = useState(true);

  const [{}, createChannel] = useCreateDiscordChannelMutation();

  const handleCreateChannel = async () => {
    try {
      const res = await createChannel({});
      if (res?.data?.createDiscordChannel)
        setChannel(res?.data?.createDiscordChannel);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (channel === "") return;
    // Check if a <widgetbot-crate> element already exists
    if (document.querySelector("widgetbot-crate")) return;

    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/npm/@widgetbot/crate@3";
    script.async = true;
    script.innerHTML = `new Crate({
      server: '${import.meta.env.VITE_APP_DISCORD_SERVER_ID}',
      channel: '${channel}',
      glyph: ['./assets/support.svg', '75%'],
      color: '#FF9800'
    })`;
    // crate.notify('If you need any help, send our support team a message!');`;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [channel]);

  return (
    <>
      {isFabVisible && channel === "" && (
        <div style={{ position: "relative" }}>
          <Fab
            size="large"
            sx={{
              position: "fixed",
              bottom: 20,
              right: 20,
              backgroundColor: "#909eab",
            }}
            onClick={handleCreateChannel}
          >
            <img
              src="./assets/support.svg"
              alt="Help"
              style={{ width: 40, height: 40 }}
            />
            <Box
              component="div"
              onClick={(e) => {
                e.stopPropagation();
                setIsFabVisible(false);
              }}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                height: 12,
                width: 12,
                backgroundColor: "#FFF",
                p: 0,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Iconify icon="bi:x" sx={{ fontSize: 22 }} color="#000" />
            </Box>
          </Fab>
        </div>
      )}
    </>
  );
}
