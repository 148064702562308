export const getActiveRoomIdAndName = () => {
    let activeRoomId;
    let activeRoomName;
    const environment = import.meta.env.VITE_APP_API;
    // Set active room from local storage - Based on environment
    if (environment.includes("staging")) {
      activeRoomId = localStorage.getItem("staging-activeRoomId");
      activeRoomName = localStorage.getItem("staging-activeRoomName");
    } else if (environment.includes("sandbox")) {
      activeRoomId = localStorage.getItem("sandbox-activeRoomId");
      activeRoomName = localStorage.getItem("sandbox-activeRoomName");
    } else if (environment.includes("localhost")) {
      activeRoomId = localStorage.getItem("localhost-activeRoomId");
      activeRoomName = localStorage.getItem("localhost-activeRoomName");
    } else {
      activeRoomId = localStorage.getItem("activeRoomId");
      activeRoomName = localStorage.getItem("activeRoomName");
    }
    return { activeRoomId, activeRoomName };
  };
  