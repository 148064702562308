import { useMemo } from "react";
import toast from "react-hot-toast";
import {
  createClient,
  Provider as GQLProvider,
  cacheExchange,
  fetchExchange,
  subscriptionExchange,
  mapExchange,
} from "urql";
import { authExchange } from "@urql/exchange-auth";
import { captureException } from "@sentry/react";
import { createClient as createWSClient, SubscribePayload } from "graphql-ws";
import useAuth from "./hooks/useAuth";
import { Auth } from "./Auth";

const auth = new Auth();

export const GqlProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { jwt, setJwt, setRefreshToken, logout } = useAuth();

  const client = useMemo(() => {
    const wsClient = createWSClient({
      url: import.meta.env.VITE_APP_WS!,
      connectionParams: () => ({
        Authorization: jwt ? `Bearer ${jwt}` : "",
      }),
      shouldRetry: () => true,
      retryWait: async function waitForServerHealthyBeforeRetry() {
        // if you have a server healthcheck, you can wait for it to become
        // healthy before retrying after an abrupt disconnect (most commonly a restart)
        await waitForHealthy();

        // after the server becomes ready, wait for a second + random 1-4s timeout
        // (avoid DDoSing yourself) and try connecting again
        await new Promise((resolve) =>
          setTimeout(resolve, 1000 + Math.random() * 3000)
        );
      },
    });

    return createClient({
      url: `${import.meta.env.VITE_APP_API!}/graphql`,
      fetchOptions: () => ({
        headers: { Authorization: jwt ? `Bearer ${jwt}` : "" },
      }),
      exchanges: [
        cacheExchange,
        mapExchange({
          async onError(error, operation) {
            if (error?.message) {
              console.info("Error message:", error.message);
              captureException(error.message.toString());
              toast.error(
                error.message?.toString()?.replace("[GraphQL]", "").trim()
              );
            }
          },
        }),
        authExchange(async (utils) => ({
          addAuthToOperation(operation) {
            // LOG THE API CALL
            // const definition = operation?.query?.definitions[0];
            // if (
            //   definition?.kind === "OperationDefinition" &&
            //   definition?.name?.kind === "Name"
            // ) {
            //   const { value } = definition.name;
            //   console.info("API Call: ", value);
            // }

            if (!jwt) return operation;
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${jwt}`,
            });
          },
          willAuthError() {
            var _isJwtStale: boolean = true;
            if (jwt) {
              _isJwtStale = auth.isJwtStale(jwt);
              if (_isJwtStale === true) {
                return true;
              } else {
                return false;
              }
            }
            return true;
          },
          didAuthError(error) {
            if (error?.toString()?.includes("Unauthorized") || error?.toString()?.includes("Invalid")) {
              return true;
            } else {
              console.error("error", error);
              return false;
            }
          },
          async refreshAuth() {
            try {
              const res = await auth.refresh();
              setJwt(res.token);
              setRefreshToken(res.refreshToken);
            } catch {
              await logout();
            }
          },
        })),
        fetchExchange,
        subscriptionExchange({
          forwardSubscription(operation) {
            return {
              subscribe: (sink) => {
                const dispose = wsClient.subscribe(
                  operation as SubscribePayload,
                  sink
                );
                return {
                  unsubscribe: dispose,
                };
              },
            };
          },
        }),
      ],
    });
  }, [jwt]);
  return <GQLProvider value={client}>{children}</GQLProvider>;
};

async function waitForHealthy() {
  while (true) {
    try {
      const res = await fetch(import.meta.env.VITE_APP_API_HEALTH!);
      if (res.status === 200) {
        return;
      }
    } catch (e) {
      console.error("waiting for server to become healthy");
      await new Promise((resolve) => setTimeout(resolve, 10000));
    }
  }
}
